.c-hero {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 30px!important;
  padding-left: 16px;
  padding-right: 16px;
  &:before {
    content: '';
    height: 250px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #000000 0%, rgba(0,0,0,0) 100%);
  }
  &__main {
    min-height: 98vh;
    .c-hero__title {
      font-weight: 500;
    }
  }
  &__car {
    min-height: 84vh;
    @media (max-width: 1500px) {
      min-height: 64vh;
    }

    @media (max-width: 768px) {
      min-height: 250px;
      margin-top: 60px;
      &::before {
        display: none;
      }
    }
  }

  &__blog {
    &--content {
      max-width: 1100px;
      text-align: center;
    }
  }

  &__contact {
    height: 30vh;
    min-height: 420px;
  }
  &__vouchery {
    &--content {
      max-width: 900px;
    }
  }
  &__title,
  &__subtitle {
    color: color-var(font, 3);
    margin-bottom: 0;
  }
  &__content {
    text-align: center;
    max-width: 900px;
    &--main {
      justify-content: space-between;
      width: 100%;
      align-items: center;
      @media(max-width: 1200px) {
        flex-direction: column;
      }
      .c-hero__title {
        @media (max-width: 1200px) {
            margin-bottom: 20px;
        }
        @media(max-width: 1200px) {
          text-align: center;
          font-size: 24px;
        }
      }
      .c-hero__buttons {
        @media(max-width: 1200px) {
          justify-content: center;
        }
      }
    }
    &--desc {
      color: #fff;
      margin-top: 20px;
    }
  }

  &__title {
    @media(max-width: 800px) {
      &.u-margin-bottom-great {
        margin-bottom: 20px!important;
      }
    }
  }

  &__buttons {
    .c-button--secondary {
      margin-left: 10px;
    }
    @media (max-width: 370px) {
      flex-direction: column;
      .c-button--secondary {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    height: 100%;
    &--main {
      position: absolute;
      bottom: 7%;
      left: 50%;
      transform: translateX(-50%);
      height: auto;
      width: 100%;
      @media(max-width: 800px) {
        bottom: 40px;
      }
    }
  }
  &--small {
    padding-top: 150px;
    min-height: 324px;
    @media (max-width: 1024px) {
      padding-top: 50px;
      min-height: 250px;
      padding-bottom: 0;
    }
  }

  &--normal {
    min-height: 398px;
    padding-top: 150px;
    @media (max-width: 1200px) {
      padding-top: 100px;
      min-height: 350px;
      padding-bottom: 0;
    }
  }

  &--big {
    height: 600px;
  }

  &--full {
    height: 710px;
    padding-top: 110px;
    margin-top: -110px;
  }

  &--secondary {
    .c-hero {
      &__content {
        text-align: left;
      }
      &__container {
        justify-content: flex-start;
      }
    }
  }

  &--video {
    @include mq($until: large) {
      min-height: 70vh;
    }
  }

  &:after {
    @include absolutePseudoContent();
    @include absPosition100();
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.4);
  }

  @each $type, $colors in $baseColors {
    $iter: 1;

    @each $color in $colors {
      &.u-color--#{$type}-#{$iter}:after {
        background-color: $color !important;
        opacity: 0.4;
      }

      $iter: $iter + 1;
    }
  }
  &__video {
    position: absolute;
    @include absPosition100();
    pointer-events: none;
    overflow: hidden;

    @include mq($until: large) {
      pointer-events: all;
    }

    .c-embed {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;

      @include mq($until: large) {
        width: 75vh * 1.7;
        height: 75vh;
        transform: translate(-40vh, 20px);
      }

      iframe, video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
